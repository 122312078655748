import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import SiteNav from "../components/SiteNav/SiteNav"

const About = () => (
  <Layout>
    <SEO title="About" />
    <div className="pure-g">
      <div className="pure-u-5-5 pure-u-md-1-5">
        <SiteNav />
      </div>
      <div className="pure-u-5-5 pure-u-md-4-5">
        <p>
          I originally built this site in the summer of 2003 as a POC to be able
          to read files from the directory system using ColdFusion. I had been
          collecting small icons for a few years before that and I just wanted a
          single place to store them AND be able to see what the icons looked
          like on a web page. After having to rebuild my development machine and
          almost losing my collection I decided to purchase a domain name, get a
          ColdFusion license and release my collection to the world. In 2008 I
          bought the domain name{" "}
          <a href="http://icons16x16.com">icons16x16.com</a> and this site was
          born.{" "}
        </p>
        <p>
          The website got decent enough traffic the first few years but the
          landscape of the internet and web development changes at such a quick
          pace I never gave it the time or effort it needed to make it big. With
          the release of{" "}
          <a
            title="https://fontawesome.com/"
            target="_new"
            href="https://fontawesome.com/"
          >
            Font Awesome
          </a>{" "}
          in 2012 and{" "}
          <a href="https://getbootstrap.com/" title="Bootstrap">
            Bootstrap
          </a>{" "}
          in 2011 the options for web design icons were far better than this
          site had to offer.{" "}
        </p>
        <p>
          So in 2020 I decided to put this site into "Archive mode". Using{" "}
          <a href="https://www.gatsbyjs.org/" title="Gatsby">
            Gatsby
          </a>{" "}
          and{" "}
          <a href="https://reactjs.org/" title="React">
            React
          </a>{" "}
          I have statically generated the site as html only. By removing the
          need for a ColdFusion application server it significantly lowers the
          cost of hosting the site and will increase the time I will still keep
          this site alive.
        </p>
        <p>Thanks for visiting my site.</p>
        <p>
          JOE
          <br />
          Jan, 2020
        </p>
      </div>
    </div>
  </Layout>
)

export default About
